<template>
  <div class="border-0 d-flex justify-content-between flex-column flex-xxl-row flex-lg-row flex-xl-row flex-md-row flex-sm-row align-items-start align-items-center mb-5 px-5">
      <div class="d-flex">
        <label
        class="d-flex align-items-center"
          >A mostrar
          <select
            class="custom-select p-0 mx-1"
            v-model="pageSize"
            @change="updateFetchPage"
          >
            <!-- <option :value="pageSize">{{ pageSize }}</option> -->
            <option v-for="(value, index) in pagesOptions"
              :key="index"
              :value="value">
              {{ value }}
            </option>
          </select>
          de {{ totalItems }} resultado{{totalItems != 1 ? 's' : '' }}.</label
        >
      </div>
      <div class="d-flex mt-3 mt-sm-0">
        <v-pagination
          v-if="pages"
          style="color: red; justify-content: end"
          v-model="page"
          :pages="pages"
          :range-size="0"
          :hideFirstButton="false"
          :hideLastButton="false"
          active-color="#efefef"
          @update:modelValue="paginatorHandler"
        />
      </div>
    </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default {
  name: 'ComponentName',
  data() {
    return {
      page: 1,
      range: 0,
      status: "",
      searchInput: "",
      startDate: "",
      endDate: "",
      pageSize: this.defaultPageSize,
    };
  },
  components: {
    VPagination,
  },
  props: {
    pageProp: Number,
    pages: Number,
    filterStatus: Array,
    showLeftInfoAndPages: {
      type: Boolean,
      default: true,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    itemsPerPage: {
      type: Number,
      default: 15,
    },
    totalItems: {
      type: Number,
      default: 0,
    },
    pagesOptions: {
      type: Array,
      default: function() {
        return [5, 10, 15, 20, 50];
      },
    },
    defaultPageSize: {
      type: Number,
      default: 15,
    },
  },
  computed: {

  },
  watch: {
    defaultPageSize() {
      this.pageSize = this.defaultPageSize;
    },
    pageProp() {
      this.page = this.pageProp;
      switch (this.page) {
        case 1:
        case 2:
        case 3:
        case this.pages:
        case this.pages - 1:
        case this.pages - 2:
          this.range = 0;
          break;
        default:
          this.range = 1;
      }
    },
  },
  emit: [
    "paginatorHandler",
    "updateStatus",
    "updateFetchPage"
  ],
  methods: {
    paginatorHandler() {
      this.$emit("paginatorHandler", this.page);
    },
    updateStatus() {
      this.$emit("updateStatus", this.status);
    },
    updateFetchPage() {
      this.$emit("updateFetchPage", 1, this.pageSize);
    }
  },
};
</script>

<style scoped>
/* Add your component-specific styles here */
</style>