<template>
  <!--begin::Tables Widget 13-->
  <div class="card row" style="border-radius: 0.5px">
    <!--begin::Header-->
    <CustomTableTopFilter
      :filterStatus="filterStatus"
      :pageProp="pageProp"
      :tableName="tableName"
      :pages="pages"
      :showFilters="showFilters"
      @paginatorHandler="paginatorHandler"
      @updateStatus="updateStatus"
      @queryForOccurences="queryForOccurences"
    ></CustomTableTopFilter>

    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body mb-5 py-3 px-0">
      <!--begin::Table container-->
      <div
        class="overflow-auto col"
        style="overflow-x: scroll !important; max-width: 100%"
      >
        <!--begin::Table-->
        <table class="table table-row-gray-500 gy-3 gs-5 mb-0">
          <!--begin::Table head-->
          <thead id="table-head">
            <tr class="fw-bolder text-muted">
              <th class="text-dark fw-bolder" style="min-width: 150px">
                Documento
              </th>
              <th class="text-dark fw-bolder" style="min-width: 150px">
                V/Encomenda
              </th>
              <th class="text-dark fw-bolder" style="min-width: 150px">
                Emissão
              </th>
              <th class="text-dark fw-bolder" style="min-width: 150px">
                Entrega Sugerida
              </th>
              <th class="text-dark fw-bolder" style="min-width: 150px">
                Última Previsão de Saída
              </th>
              <!-- <th class="text-dark fw-bolder" style="min-width: 150px">
                Fechada
              </th> -->
              <th
                v-if="showStatus"
                class="text-center text-dark fw-bolder"
                style="min-width: 150px"
              >
                Estado
              </th>
              <!-- button that allows copy all order -->
              <th v-if="showCopydBtn" class="text-dark fw-bolder text-center">
                Copiar
              </th>
              <th class="text-end"></th>
            </tr>
          </thead>
          <!--end::Table head-->

          <!--begin::Table body-->
          <tbody>
            <template v-if="loading && list.length == 0"
              ><tr v-for="r in 15" :key="r" style="height: 38px"></tr
            ></template>
            <template v-for="(item, index) in list" :key="index">
              <tr
                :style="rowColor(item.id)"
                class="border-bot"
                style="vertical-align: middle"
              >
                <td class="text-dark fw-bolder fs-6">
                  {{ item.strNumero }}
                </td>
                <td class="fs-7">
                  {{ item.strNumRequisicao }}
                </td>

                <td class="fs-6">
                  {{ getFormatedDate(item.dtmData) }}
                </td>
                <td class="fs-6">
                  {{ getFormatedDate(item.dtmDataEntregaCab) }}
                </td>
                <td class="fs-6">
                  {{ getLastDeliveryDate(item.Mov_Encomenda_Lin, item.strCodEstadoDoc) }}
                </td>
                <!-- <td class="fs-6">
                  {{ item.bitParcIntegrada == true ? "Sim" : "Não" }}
                </td> -->
                <td v-if="showStatus" class="text-center">
                  <span
                    :class="color(item.CA_EstadoPortal)"
                    :style="orderStatusColors(item.CA_EstadoPortal)"
                    class="badge"
                    >{{ getStatus(item.CA_EstadoPortal) }}</span
                  >
                </td>
                <td v-if="showCopydBtn" class="text-center pt-1 pb-1">
                  <button
                    class="btn btn-sm btn-icon btn-active-light-primary mx-1 my-1"
                    style="
                      border: 1px solid #d1d3e0;
                      background-color: white;
                      height: 2.4rem;
                      width: 2.4rem;
                    "
                    @click="copyOrderToCart(item)"
                  >
                    <i class="bi bi-clipboard2-plus"></i>
                  </button>
                </td>

                <td class="text-end pt-1 pb-1" style="min-width: 100px">
                  <button
                    v-if="showDownloadBtn && item.invoicePdf"
                    @click="downloadPdf"
                    class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm mx-1 my-1"
                    style="
                      background-color: white;
                      border: 1px solid #d1d3e0;
                      height: 2.4rem;
                      width: 2.4rem;
                    "
                    id="btn-pdf-download"
                    :download="item.documentName + item.documentNumber"
                    :href="item.invoicePdf"
                    title="Download pdf document"
                  >
                    <span class="svg-icon svg-icon-3" v-if="showDownloadBtn">
                      <inline-svg src="media/icons/duotune/files/fil009.svg" />
                    </span>
                  </button>

                  <button
                    @click="toggleShowRowDetail($event, item.id)"
                    class="btn btn-sm btn-icon btn-main-table btn-active-light-primary mx-1 my-1"
                  >
                    <span class="svg-icon svg-icon-3">
                      <inline-svg class="svg-icon-transition" src="media/icons/duotune/arrows/arr072.svg" />
                    </span>
                  </button>
                </td>
              </tr>
              <transition name="subtable">
              <tr v-if="showRow(item.id)">
                <td colspan="9">
                  <table
                    class="table table-row-dashed table-row-gray-500 gy-3 gs-5 mb-0"
                  >
                    <thead class="border-solid-sub-table">
                      <tr class="fw-bold fs-8 text-gray-800">
                        <th scope="col">Referência</th>
                        <th scope="col">Designação</th>
                        <th scope="col">Previsão de Saída</th>
                        <th scope="col" class="text-center">Tipo Stock</th>
                        <th scope="col" class="text-center">Unidade</th>
                        <th scope="col" class="text-center">Qtd.</th>
                        <th
                          v-if="showPendingQtt"
                          scope="col"
                          class="text-center"
                        >
                          Qtd. pendente
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      v-for="(product, ind) in item.Mov_Encomenda_Lin"
                      :key="ind"
                      class="border-dotted-sub-table text-muted"
                    >
                      <tr
                        class=""
                        v-if="
                          product.strCodArtigo &&
                          product.strCodArtigo.trim().length > 0
                        "
                      >
                        <td scope="row">{{ product.strCodArtigo }}</td>
                        <td>{{ product.strDescArtigo }}</td>
                        <td>
                          <span
                            :style="
                              getDeliveryDateColor(product.dtmDataEntrega, item.strCodEstadoDoc)
                            "
                            class="badge text-muted"
                            >{{
                              getFormatedDeliveryDate(product.dtmDataEntrega, item.strCodEstadoDoc)
                            }}</span
                          >
                        </td>
                        <td class="text-center">{{ product.CA_Campo04 }}</td>
                        <td class="text-center">{{ product.Tbl_Gce_Artigos.strAbrevMedStk }}</td>
                        <td class="text-center">{{ product.fltQuantidade }}</td>
                        <td class="text-center">
                          {{ product.fltQuantidadePend }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              </transition>
            </template>
          </tbody>
          <!--end::Table body-->
        </table>
        <!--end::Table-->
      </div>
      <!--end::Table container-->
    </div>
    <!--begin::Body-->

    <CustomTableBottomPagination
      :pageProp="pageProp"
      :pages="pages"
      :itemsPerPage="itemsPerPage"
      :totalItems="totalItems"
      @updateFetchPage="updateFetchPage"
      @paginatorHandler="paginatorHandler"
      @updateStatus="updateStatus"
    >
    </CustomTableBottomPagination>

    <transition name="loader">
      <div v-if="loading" class="overlay-loader">
        <div class="text-center">
          <span
            class="spinner-border spinner-border-lg custom-loader align-middle my-2 text-primary"
            role="status"
          ></span
          ><br /><span class="text-white fs-4 fw-bold visually-hidden"
            >A carregar...</span
          >
        </div>
      </div>
    </transition>

  </div>
  <!--end::Tables Widget 13-->
</template>

<script lang="js">
import {defineComponent} from "vue";
import Dropdown2 from "@/components/dropdown/Dropdown2.vue";
import CustomTableTopFilter from "@/components/customTables/CustomTableTopFilter.vue";
import CustomTableBottomPagination from "@/components/customTables/CustomTableBottomPagination.vue";
import {boolean} from "yup/lib/locale";
import store from "@/store";

/* eslint-disable */
export default defineComponent({
  name: "CustomTableOrdersOccurences",
  data() {
    return {
      rowsToShow: [""],
    };
  },
  components: {
    Dropdown2,
    CustomTableTopFilter,
    CustomTableBottomPagination
  },
  props: {
    widgetClasses: String,
    tableName: String,
    list: Array,
    showStatus: {
      type: Boolean,
      default: true,
    },
    pageProp: Number,
    filterStatus: Array,
    filterStatusEti: Array,
    pages: Number,
    showDownloadBtn: {
      type: Boolean,
      default: true,
    },
    showCopydBtn: {
      type: Boolean,
      default: true,
    },
    showPendingQtt: {
      type: Boolean,
      default: false,
    },
    showFilters: {
      type: Boolean,
      default: true,
    },
    loading: Boolean,
    pageProp: Number,
    pages: Number,
    itemsPerPage: Number,
    totalItems: Number,
  },
  computed: {
    storedProducts() {
      return store.getters.getProducts.length;
    },
  },
  emit: ["paginatorHandler", "updateStatus", "queryForOccurences", "changeTable", "copyOrderToCart","updateFetchPage"],
  methods: {
    
    downloadpdf() {
    },

    showRow(rowId) {
      return this.rowsToShow.includes(rowId);
    },

    /*
    * ----------------DATA MANIPULATION----------------
    */
    toggleShowRowDetail(e, rowId) {
      const index = this.rowsToShow.indexOf(rowId);
      const iconElement = e.target.closest('svg') || e.target.querySelector('svg');
      const button = e.target.closest('button');
      if (index > -1) {
        this.rowsToShow.splice(index, 1);
        iconElement.style.transform = 'rotate(0deg)';
        button.classList.remove("active");
        button.blur();
        return;
      } else {
        this.rowsToShow.push(rowId);
        iconElement.style.transform = 'rotate(180deg)';
        button.classList.add("active");
        return;
      }
    },

    /*
    * ------------------EMITS------------------
    */
    updateFetchPage(page, pagesSize) {
      this.$emit("updateFetchPage", page, pagesSize);
    },

    paginatorHandler(page) {
      this.$emit("paginatorHandler", page);
    },

    updateStatus(status) {
      this.$emit("updateStatus", status);
    },

    queryForOccurences(searchInput) {
      this.$emit("queryForOccurences", searchInput);
    },

    copyOrderToCart(item) {
      let unavailableProducts = [];

      const products = item.Mov_Encomenda_Lin.map((product, index) => {

        if(!product.strCodArtigo) return;

        let prod = null;

        prod = store.getters.getProducts.find(
          (prod) => prod.ref.trim() === product.strCodArtigo
        );

        if(!prod) {
          unavailableProducts.push(product);
          return;
        }

        if(prod) {
          return {
            strCodArtigo: product.strCodArtigo,
            strDescricao: product.strDescArtigo,
            fltQuantidade: product.fltQuantidade,
            tipoStock: prod.tipoStock,
          };
        }

        return {
          strCodArtigo: product.strCodArtigo,
          strDescricao: product.strDescArtigo,
          fltQuantidade: product.fltQuantidade,
        };
      });

      this.$emit("copyOrderToCart", products, unavailableProducts);
    },

    /*
    * -------------------UTILS--------------------
    */
    getFormatedDate(date) {
      return new Date(date).toLocaleDateString('pt-pt').replaceAll('/', '.')
    },

    getFormatedDeliveryDate(date, status = null) {

      if(status && status.toUpperCase() == "EMAPV") return "Em aprovação";

      if(!date) return;

      let formatedDate = this.getFormatedDate(date);
      let dayMonthYear = formatedDate.split('.');

      if(dayMonthYear[1] == '12') {
        if(dayMonthYear[0] == '27') {
          return "Aguarda Prazo";
        }
        if(dayMonthYear[0] == '28') {
          return "Comercial Leva";
        }
        if(dayMonthYear[0] == '29') {
          return "Confirmação pelo cliente";
        }
        if(dayMonthYear[0] == '30') {
          return "Próximo envio";
        }
      }

      return formatedDate;
    },

    getLastDeliveryDate(deliveryProducts, status = null) {

      if(deliveryProducts.length < 1 || (status && status.toUpperCase() == "EMAPV")) return "Nenhuma";

      if(deliveryProducts.length < 1) return "";

      let lastDate = "";
      deliveryProducts.forEach((product) => {

        if(!product.dtmDataEntrega) return;

        let date = new Date(product.dtmDataEntrega);

        let formatedDate = this.getFormatedDate(date);
        let dayMonthYear = formatedDate.split('.');
        if(dayMonthYear[1] == '12') {
          if(dayMonthYear[0] == '27') {
            return;
          }
          if(dayMonthYear[0] == '28') {
            return;
          }
          if(dayMonthYear[0] == '29') {
            return;
          }
          if(dayMonthYear[0] == '30') {
            return;
          }
        }
        if(lastDate === "") {
          lastDate = date;
        } 
        if (date > lastDate) {
          lastDate = date;
        }

      });
      return lastDate === "" ? "Nenhuma" : lastDate.toLocaleDateString('pt-pt').replaceAll('/', '.');
    },

    getDeliveryDateColor(date, status = null) {
      if(status && status.toUpperCase() == "EMAPV") return "background-color: #DDF3FF;";
      let displayString = this.getFormatedDeliveryDate(date)
      if(!(/^[A-Za-z\s]+$/).test(displayString)) return "";
      return "background-color: #DDF3FF;"
    },

    getStatus(statusCode) {

      //filter status on statuslist and return the status name
      const status = this.filterStatus.find(
          (status) => status == statusCode
      );

      if (status) {
        return status;
      }

      return status;

    },

    /*
    * -------------------STYLES-------------------
     */
    color(elem) {
      let str = "badge-light-";
      let color = elem === "Em Processamento" ? "success" : "primary";
      return str + color;
    },
    rowColor(id) {
      const index = this.list.findIndex(
          (item) => item.id == id
      );
      if (index % 2 == 0) {
        return "background-color: #DDF3FF;";
      } else {
        return "background-color: rgba(255, 255, 255, 0.07);";
      }
    },
    orderStatusColors(status) {
      switch (status.toUpperCase()) {
        /* case "ACE":
          return "background-color: #dfdddd; color: black !important; min-width:150px; border: 1px solid #181c32;"; 
        case "REJEI":
        case "NACE":
          return "background-color: #EFCBCB; color: black !important; min-width:150px; border: 1px solid #F14D75;";  
          */
        case "CONCLUIDO":
          //CONC
          return "background-color: #D8FFE7; color: black !important; min-width:150px; border: 1px solid #2CC268;";
        case "PENDENTE":
          //PEND
          return "background-color: #FEF3D8; color: black !important; min-width:150px; border: 1px solid #FFB01A;";
        case "EM APROVAÇÃO":
          //EMAPV
          return "background-color: #d8e5fe; color: black !important; min-width:150px; border: 1px solid #216cfc;";
        default:
          return "background-color: #e4ebf0; color: #181c32 !important; min-width:150px;";
      }
    },

  },
});
</script>

<style scoped>

.fixTableHead {
  overflow-y: auto;
}

.fixTableHead thead th {
  position: sticky;
  top: 10;
}

table {
  border-collapse: collapse;
  width: 100%;
}

.border-solid-sub-table {
  border-bottom: 1px solid;
  border-bottom-color: black !important;
}

.border-dotted-sub-table {
  border-bottom: 1px dotted;
  border-bottom-color: grey !important;
}

.border-dotted-sub-table:last-child {
  border-bottom: none !important;
}

</style>
