<template>
  <CustomTableOrdersOccurences
    style="min-width: 100%; max-width: 100px"
    :tableName="tableName"
    :showStatus="true"
    :list="list"
    :pageProp="page"
    :pages="pages"
    :itemsPerPage="itemsPerPage"
    :totalItems="totalItems"
    :filterStatus="filterStatus"
    :filterStatusEti="filterStatusEti"
    :showDownloadBtn="false"
    :showPendingQtt="true"
    :loading="loading"
    @updateFetchPage="updateFetchPage"
    @paginatorHandler="paginatorHandler"
    @updateStatus="updateStatus"
    @queryForOccurences="queryForOccurences"
    @changeTable="changeTable"
    @copyOrderToCart="copyOrderToCart"
  ></CustomTableOrdersOccurences>
</template>
<script>
import { defineComponent, onMounted, reactive } from "vue";
import CustomTableOrdersOccurences from "@/components/customTables/CustomTableOrdersOccurences.vue";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import store from "@/store";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "Encomendas",
  components: {
    CustomTableOrdersOccurences,
  },
  data() {
    return {
      tableName: "Detalhe",
      page: 1,
      pages: 1,
      itemsPerPage: 15,
      totalItems: 0,
      list: [],
      orders: Array,
      status: "",
      searchInput: "",
      startDate: "",
      endDate: "",
      filterStatus: ["Concluido", "Pendente", "Em Aprovação"],
      filterStatusEti: [],
      loading: false,
    };
  },
  computed: {
    storedProducts() {
      return store.getters.getProducts.length;
    }
  },
  watch: {},
  methods: {
    updateFetchPage(page, pagesSize) {
      this.page = page;
      this.itemsPerPage = pagesSize;
      this.loadOrders(page);
    },
    /*
     * ----------------DATA MANIPULATION----------------
     */
    paginatorHandler(page) {
      this.page = page;
      this.loadOrders(page);
    },
    changeTable(table) {
      this.chosenTable = table;
    },

    //filters by status, update pages, and list to show
    updateStatus(status) {
      //get status string and iterate stausEti to get key
      if (status != "Todos" && status.trim() != "") {
        this.status = this.filterStatus.find(
          (item) => item === status
        );
      } else {
        this.status = status;
      }
      this.paginatorHandler(1);
    },
    queryForOccurences(objValue) {
      this.searchInput = objValue.searchInput;
      this.startDate = objValue.startDate;
      this.endDate = objValue.endDate;

      this.paginatorHandler(1);
    },

    MapDocTypes(docTypes) {
      this.filterStatus = docTypes
        .map((docType) => {
          let str = docType.strDescricao.toLowerCase();
          // In this project the client just wants some of these options for the filter
          if (
            str == "em aprovacao" ||
            str == "em aprovação" ||
            str == "pendente" ||
            str == "concluido" ||
            str == "concluído"
          )
            return docType.strDescricao;
          return null;
        })
        .filter(Boolean);
    },

    copyOrderToCart(orderProducts, unavailableProducts = []) {

      store.commit("addProductToCartNoMap", orderProducts);

      toast.success("Encomenda copiada", {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: "slide",
        theme: "colored",
        type: "success",
      });

      if (unavailableProducts && unavailableProducts.length <= 0) return;

      Swal.fire({
        title: "<span>Produtos não disponíveis</span>",
        icon: "warning",
        html: `<p>Os seguintes produtos não foram copiados para a nova encomenda por já não estarem disponíveis:</p>${unavailableProducts.map(
          (p) => "<p>&#8226 " + p.strCodArtigo + " - " + p.strDescArtigo + "</p>"
        ).join('\n')}`,
        confirmButtonText: "Ok!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
      });

    },

    /*
     * ------------------API FETCHING------------------
     */

    loadOrders(page) {
      this.loading = true;
      let queryString = this.getQueryString(page);

      ApiService.get("/encomendas", queryString)
        .then(({ data }) => {
          this.totalItems = data.TotalPages;
          this.pages = Math.ceil(this.totalItems / this.itemsPerPage);
          this.list = data.Data;
          this.loading = false;
        })
        .catch(({ data }) => {
          console.log(data);
          this.loading = false;
        });
    },

    loadFilterStatus() {
      ApiService.get("Utils/estadosDocs")
        .then(({ data }) => {
          this.filterStatusEti = data.Data;
          this.MapDocTypes(data.Data);
        })
        .catch(({ data }) => {
          console.log(data);
        });
    },

    /*
     * -------------------UTILS--------------------
     */

    getQueryString(page) {
      let queryString = `?pageNumber=${page}&pageSize=${this.itemsPerPage}&intCodEntidade=${store.getters.currentUser.numcl}`;

      if (this.startDate) {
        queryString += `&DtmInicial=${this.startDate}`;
      }
      if (this.endDate) {
        queryString += `&DtmFinal=${this.endDate}`;
      }
      if (this.status) {
        queryString += `&StrCodEstado=${this.status}`;
      }
      if (this.searchInput) {
        //queryString += `&intNumero=${this.searchInput}`;
        queryString += `&strNumero=${this.searchInput}&strNumRequisicao=${this.searchInput}`;
      }

      return queryString;
    },

    filterByStatusAndDocNumber() {
      //this.setAllOccurences();
      if (!store.getters.getOrders.length) return;

      let ordersList = store.getters.getOrders.filter(
        (p) =>
          (p.documentStatus + "").includes(this.status) &&
          (p.documentNumber + "").includes(this.searchInput)
      );

      return ordersList;
    },

    /*
     * -------------------STYLES-------------------
     */
  },
  mounted() {
    setCurrentPageBreadcrumbs("Encomendas");
    //on first load we will load only from last year

    /* Set up today as starting date
    let startDate = new Date();
    startDate.setFullYear(startDate.getFullYear() - 1);
    this.startDate = startDate.toISOString().substring(0, 10);*/

    this.loadOrders(1);
    this.loadFilterStatus();

    if (!this.storedProducts || this.storedProducts.length === 0) {
      store.dispatch("loadProducts");
    }
  },
});
</script>
